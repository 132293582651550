import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import NavigationBar from "./NavigationBar";

import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Intro from "./pages/Intro";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/intro" element={<Intro />} />

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
