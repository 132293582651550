import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "tw-elements-react/dist/css/tw-elements-react.min.css";

const root = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);
