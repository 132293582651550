// Layout.js
import React from "react";
import NavigationBar from "./NavigationBar";
import Stickysocialbar from "./Stickysocialbar";

function Layout({ children }) {
  return (
    <div>
      <NavigationBar />
      <Stickysocialbar />
      <main>{children}</main>
      {/* Footer or other common elements */}
    </div>
  );
}

export default Layout;
