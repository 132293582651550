import React, { useRef } from "react";
import "./Home.css";
import Layout from "./Layout";
import About from "./About";
import Contact from "./Contact";
import AnimatedText from "./AnimatedText";
import NavigationBar from "./NavigationBar";
import Intro from "./pages/Intro";
import Stickysocialbar from "./Stickysocialbar";
const Home = () => {
  // Create refs for the sections to scroll to
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const homeRef = useRef(null);
  // Function to scroll to a specific section
  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Function to scroll to the Contact section
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Stickysocialbar />
      <NavigationBar // Pass scroll functions as props to the NavigationBar component
        scrollToAbout={scrollToAbout}
        scrollToContact={scrollToContact}
        scrolltoHome={scrollToHome}
      />
      <div ref={homeRef}>
        <div className="bg-gray-700 items-center ">
          <div className="flex flex-col md:flex-row items-center h-screen ">
            <div className="w-full md:w-1/2 flex flex-col justify-center">
              <div className="md:ml-5 text-center  ml-8 ">
                <h1
                  style={{
                    fontFamily: "cursive",
                  }}
                  className="text-3xl text-gray-200 font-bold mb-4 "
                >
                  Hello, This is Susan
                </h1>
                <div style={{ border: "1001px" }}>
                  <p
                    style={{
                      fontFamily: "cursive",
                      padding: "10px",
                    }}
                    className="text-white mb-1 text-lg text-gray-500 md:text-xl dark:text-gray-400 p-3"
                  >
                    <div style={{ height: " 50px" }}>
                      Myself <AnimatedText />
                    </div>
                  </p>
                </div>
              </div>
              <br />
              <div class="flex flex-col items-center text-gray-200  text-3xl mb-1 ">
                <h2
                  class=" text-center"
                  style={{
                    fontFamily: "cursive",
                  }}
                >
                  Skills
                </h2>
                <div class="flex flex-wrap justify-center ">
                  <img
                    className="w-20 h-20 mb-4 mr-4 img-glow1"
                    src="/images/html.png"
                    alt="HTML"
                  />
                  <img
                    class="w-20 h-20 mb-4 mr-4 img-glow2"
                    src="/images/css.png"
                    alt="CSS"
                  />
                  <img
                    class="w-20 h-20 mb-4 mr-4 img-glow3"
                    src="/images/react.png"
                    alt="React"
                  />
                  <img
                    class="w-20 h-20 mb-4 mr-4 img-glow4"
                    src="/images/js.png"
                    alt="JavaScript"
                  />
                  <img
                    class="w-20 h-20 mb-4 mr-4 img-glow5"
                    src="/images/pentest.png"
                    alt="JavaScript"
                  />
                </div>
              </div>
            </div>

            {/* Right Side (Image) */}
            <div className="w-full md:w-1/2 flex justify-center mb-8 p-7">
              <img
                className="w-72 h-72 mb-8 p-2 profile "
                src="/images/profile1.jpg"
                alt="susan-profile"
              />
            </div>
          </div>
        </div>
      </div>
      <Intro />
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
    </>
  );
};

export default Home;
