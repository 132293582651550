import React from "react";
// import "./About.css";
// import SimpleSlider from "./SimpleSlider";

const About = () => {
  return (
    <div className="bg-gray-800 ">
      <h1
        style={{ fontFamily: "cursive" }}
        className=" text-gray-200 text-4xl  text-center p-2   font-extrabold  "
      >
        Projects
      </h1>

      <div className="flex flex-wrap justify-center p-5 ">
        <div className="flex flex-col md:flex-row items-center md:mt-5 mt-12 ">
          <div
            className=" bg-transparent flex flex-col w-1/2   max-w-xs w-full md:w-1/2 flex flex-col justify-center max-w-xs ml-8  bg-green-200 border border-gray-200
      rounded-lg shadow dark:bg-gray-800  "
            style={{ height: "400px" }}
          >
            <div style={{ width: "100%", height: "200px", overflow: "hidden" }}>
              <img
                className="rounded-t-lg  object-cover "
                src="./images/weather.png"
                alt=""
              />
            </div>

            <div className="p-5 ">
              <h5
                style={{
                  fontFamily: "cursive",
                }}
                className=" text-gray-200 mb-0 text-2xl font-bold tracking-tight dark:text-white"
              >
                Weather app
              </h5>

              <p
                style={{
                  fontFamily: "cursive",
                }}
                className="font-normal text-wrap text-gray-200 dark:text-gray-400 p-2"
              >
                This weather app uses restful api to fetch the weather data from
                open weather api
              </p>
              <a
                href=""
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Demo
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
              <a
                href="https://github.com/susank00/weather-forcaster"
                className=" ml-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Github
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>

          {/* another card element >>>>>>>>>>>>>>>>>>>>>>> */}
          <div
            className=" bg-transparent flex flex-col w-1/2   max-w-xs w-full md:w-1/2 flex flex-col justify-center max-w-xs ml-8  bg-green-200 border border-gray-200
      rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  "
            style={{ height: "400px" }}
          >
            <div style={{ width: "100%", height: "200px", overflow: "hidden" }}>
              <img
                className="rounded-t-lg  object-cover "
                src="./images/movies.png"
                alt=""
              />
            </div>

            <div className="p-2 ">
              <a href="#">
                <h5
                  style={{
                    fontFamily: "cursive",
                  }}
                  className=" text-gray-200 mb-0 text-2xl font-bold tracking-tight dark:text-white"
                >
                  Movie suggestor
                </h5>
              </a>

              <p
                style={{
                  fontFamily: "cursive",
                }}
                className="font-normal text-gray-200 dark:text-gray-400 p-2"
              >
                This project suggest the user various movies which fetch api
                from dynoacademy has login functionality
              </p>
              <a
                href="#"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Demo
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
              <a
                href="https://github.com/susank00/movie-suggestor"
                className=" ml-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Github
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>

          {/* another >>>>>>>>>>>>>>>>>>> */}

          <div
            className="  bg-transparent flex flex-col w-1/2   max-w-xs w-full md:w-1/2 flex flex-col justify-center max-w-xs ml-8  bg-green-200 border border-gray-200
      rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  "
            style={{ height: "400px" }}
          >
            <div style={{ width: "100%", height: "200px", overflow: "hidden" }}>
              <img
                className="rounded-t-lg  object-cover "
                src="./images/pet.png"
                alt=""
              />
            </div>

            <div className="p-5">
              <h5
                style={{
                  fontFamily: "cursive",
                }}
                className=" text-gray-200 mb-0 text-2xl font-bold tracking-tight dark:text-white"
              >
                PetStore
              </h5>

              <p
                style={{
                  fontFamily: "cursive",
                }}
                className="font-normal text-wrap text-gray-200 dark:text-gray-400 p-1"
              >
                The Dynamic Petsore app is made with MERN stack with different
                functionalities like login ,buy,payment
              </p>
              <a
                href="https://pet-store-eight.vercel.app"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Demo
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
              <a
                href="https://github.com/susank00/pet-store"
                className=" ml-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Github
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
