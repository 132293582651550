import React from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name");
    const message = formData.get("message");

    // Validate message length
    const messageWords = message.trim().split(/\s+/);
    if (messageWords.length > 10) {
      alert("Please limit your message to 10 words.");
      return;
    }

    // Check for script tags in the message
    const scriptRegex = /<script.*?>|<\/script>/gi;
    if (scriptRegex.test(message)) {
      alert("Script tags are not allowed.");
      return;
    }
    // Check for dirty words in the name and message
    const dirtyWords = [
      "randi",
      "lado",
      "muji",
      "fuck",
      "fuck you",
      "motherfucker",
      "fucker",
      "rada",
    ]; // Add more words as needed
    const containsDirtyWord = dirtyWords.some((word) =>
      new RegExp(`\\b${word}\\b`, "i").test(name + " " + message)
    );

    if (containsDirtyWord) {
      alert("Please avoid using inappropriate language.");
      return;
    }
    // If validation passes, submit the form
    emailjs
      .sendForm(
        "service_i39t7dx",
        "template_g32eamk",
        e.target,
        "1Fzgtg6f3l9giWfbq"
      )
      .then((result) => {
        console.log("Email sent successfully:", result.text);
        alert("Message sent successfully!");
        e.target.reset();
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("An error occurred while sending the message.");
      });
  };

  return (
    <div
      className="bg-gray-700 min-h-screen flex flex-col justify-center items-center px-4 py-8 "
      style={{ fontFamily: "cursive" }}
    >
      <div className="max-w-md w-full border border-gray-600 rounded-md shadow-md p-8">
        <h1 className="text-3xl lg:text-4xl text-gray-200 font-semibold text-center ">
          Send a Message
        </h1>
        <p className="text-gray-300 p-6 text-center">
          Your message will be received in my inbox.
        </p>
        <form onSubmit={handleSubmit} id="contact_form">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-300 font-semibold mb-1"
            >
              Your Name:
            </label>
            <input
              type="name"
              id="name"
              name="name"
              className="w-full bg-gray-800 text-gray-200 border border-gray-600 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your name"
              maxLength="8"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-gray-300 font-semibold mb-1"
            >
              Your Message:
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              className="w-full bg-gray-800 text-gray-200 border border-gray-600 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your message "
              maxLength="10" // Assuming each word is on average 5 characters long
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white font-semibold py-2 rounded-md hover:from-blue-600 hover:to-blue-800 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
