import React from "react";

const NavigationBar = ({ scrollToAbout, scrollToContact, scrolltoHome }) => {
  return (
    <>
      <nav className="bg-grey dark:bg-gray-700 fixed w-full z-20 top-0 start-0 border-bdark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-0">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <span className="self-center text-3xl font-semibold whitespace-nowrap text-white">
              <span
                style={{
                  fontFamily: "Lucida Handwriting",
                  fontStyle: "cursive",
                }}
              >
                Portfolio
              </span>
            </span>
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className=" bg-grey items-center justify-between hidden md:flex md:w-auto md:order-1 "
            id="navbar-sticky"
          >
            <ul className=" bg-grey flex flex-col p-4 md:p-0 mt-4 font-medium   md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="#home"
                  className="block py-2 px-3 text-white text-3xl bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-white-500 bg-grey"
                  aria-current="page"
                  onClick={scrolltoHome}
                >
                  <span style={{ fontFamily: "cursive" }}>Home</span>
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="block py-2 px-3  text-3xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  onClick={scrollToAbout}
                >
                  <span style={{ fontFamily: "cursive" }}>About</span>
                </a>
              </li>

              <li>
                <a
                  href="#contact"
                  className="block py-2 px-3 text-white text-3xl rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  onClick={scrollToContact}
                >
                  <span style={{ fontFamily: "cursive" }}>Contact</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
