import React, { useState, useEffect } from "react";

function TypewriterText() {
  const phrases = ["Web developer", "Cyber Security enthusiast"];
  const [text, setText] = useState("".padEnd(20, " "));
  const [typingState, setTypingState] = useState({
    isTyping: true,
    phraseIndex: 0,
    charIndex: 0,
  });

  useEffect(() => {
    let typingTimer;

    const { isTyping, phraseIndex, charIndex } = typingState;

    if (isTyping) {
      if (charIndex < phrases[phraseIndex].length) {
        typingTimer = setTimeout(() => {
          setText((prevText) => prevText + phrases[phraseIndex][charIndex]);
          setTypingState((prevState) => ({
            ...prevState,
            charIndex: prevState.charIndex + 1,
          }));
        }, 200); // Typing speed: 100ms per character
      } else {
        setTimeout(() => {
          setTypingState((prevState) => ({
            ...prevState,
            isTyping: false,
          }));
        }, 1000); // Pause before erasing
      }
    } else {
      if (text.length > 0) {
        typingTimer = setTimeout(() => {
          setText((prevText) => prevText.slice(0, -1));
        }, 50); // Erasing speed: 50ms per character
      } else {
        setTypingState((prevState) => ({
          ...prevState,
          isTyping: true,
          phraseIndex: (prevState.phraseIndex + 1) % phrases.length,
          charIndex: 0, // Reset charIndex when transitioning to the next phrase
        }));
      }
    }

    return () => clearTimeout(typingTimer);
  }, [text, typingState]);

  return (
    <p
      style={{
        fontFamily: "cursive",
        padding: "5px",
        margin: "auto",
      }}
      className="text-white mb-1 text-lg text-gray-500 md:text-xl dark:text-gray-400 p-3"
    >
      {text}
    </p>
  );
}

export default TypewriterText;
