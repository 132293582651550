import "./stickybar.css";
const Stickysocialbar = () => {
  return (
    <>
      <div className="text-grey-50">
        <div className="sticky-container">
          <ul className="sticky">
            <li>
              <img
                width="48"
                height="35"
                title=""
                alt=""
                src="./images/facebook.png"
              />
              <p>Facebook</p>
            </li>
            <li>
              <a href="https://github.com/susank00/">
                <img
                  width="48"
                  height="32"
                  title=""
                  alt=""
                  src="./images/github.png"
                />

                <p>Github</p>
              </a>
            </li>

            <li>
              <a href="https://www.linkedin.com/in/susan-khatri-3a1869260/">
                <img
                  width="48"
                  height="32"
                  title=""
                  alt=""
                  src="./images/linkedinn.png"
                />

                <p>Linkedin</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Stickysocialbar;
