const Intro = () => {
  return (
    <>
      <div className="bg-gray-500 text-3xl text-gray-200 text-center max-w-auto p-10">
        <h1
          style={{ fontFamily: "cursive" }}
          className="text-4xl font-bold inline border-b-4 border-gray-500 underline"
        >
          About
        </h1>
        <div className="flex justify-center items-center">
          {/* <p className="text-xl mt-20 max-sm:mt-10max-w-prose text-center  leading-relaxed">
           */}
          <p className="text-xl mt-20 max-sm:mt-10 max-w-xl sm:max-w lg:max-w-lg text-justify">
            Hello! I'm a dedicated React enthusiast and aspiring web developer.
            My journey into coding began with a spark of curiosity that has now
            grown into a passion for crafting engaging and dynamic user
            interfaces. I thrive on the modular and reusable nature of React's
            components, using them to bring ideas to life. Beyond coding, I'm a
            collaborative team player, always eager to learn and contribute.
            Let's connect and explore the endless possibilities of technology
            together!
          </p>
        </div>
      </div>
    </>
  );
};
export default Intro;
